import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BalanceBadge } from './Fitcoin';
import TappableCard from './TappableCard';

import logo from '../../images/common/visitfulllogo.png';

const HeaderContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 15px;
`;

const HeaderLogo = styled.img`
  height: 1.875rem;
`;

const HomeHeader = ({ balance, history, showFitCoins, sponsorLogo }) => (
  <HeaderContainer>
    <HeaderLogo src={sponsorLogo} alt="Ola Logo" />
    {showFitCoins && (
      <TappableCard onTap={() => history.push('/home/rewards')}>
        <BalanceBadge balance={balance} />
      </TappableCard>
    )}
  </HeaderContainer>
);

HomeHeader.propTypes = {
  balance: PropTypes.string.isRequired,
  showFitCoins: PropTypes.bool.isRequired,
};

export default HomeHeader;
